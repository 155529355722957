import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StorageAction } from "./storage.actions";
import { StorageStateModel } from "./storage.model";
import { STORAGE_STATE } from "../state";

@State<StorageStateModel>({
  name: STORAGE_STATE,
  defaults: {
    refreshToken: undefined,
    accessToken: undefined,
    rememberMe: false
  }
})
export class StorageState {
  @Selector()
  static refreshToken({ refreshToken }: StorageStateModel) {
    return refreshToken;
  }

  @Selector()
  static accessToken({ accessToken }: StorageStateModel) {
    return accessToken;
  }
  @Action(StorageAction.SetAccessToken)
  setAccessToken({ patchState }: StateContext<StorageStateModel>, { accessToken }: StorageAction.SetAccessToken) {
    patchState({
      accessToken
    });
  }

  @Action(StorageAction.SetRefreshToken)
  setRefreshToken({ patchState }: StateContext<StorageStateModel>, { refreshToken }: StorageAction.SetRefreshToken) {
    console.log('setRefreshToken', refreshToken);
    patchState({
      refreshToken
    });
  }

  @Action(StorageAction.ClearAccessRefreshToken)
  clearAccessRefreshToken({ patchState }: StateContext<StorageStateModel>) {
    patchState({
      accessToken: undefined,
      refreshToken: undefined
    });
  }

  @Selector()
  static rememberMe(state: StorageStateModel) {
    return state.rememberMe
  }

  @Action(StorageAction.RememberMe)
  setRememberMe({ patchState }: StateContext<StorageStateModel>, { value }: StorageAction.RememberMe) {
    patchState({
      rememberMe: value
    })
  }
}
