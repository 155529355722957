import { inject, Injectable } from '@angular/core';
import { State, Action, StateContext, Store, Selector } from '@ngxs/store';
import { lastValueFrom, Observable, tap } from "rxjs";
import { AuthAction } from "./auth.actions";
import { isTokenValid } from "../auth.utils";
import { AuthService } from "../services/auth.service";
import { Navigate } from "@ngxs/router-plugin";
import { StorageState } from "../../../storage/storage.state";
import { StorageAction } from "../../../storage/storage.actions";
import { AccountStore } from "../../../shared/account/data-access/state/account.store";

export interface AuthStateModel {
  isLoggedIn: boolean;
  accessToken?: string;
  refreshToken?: string;
  prevFailedUrl: string,
  rememberMe: boolean,
  isLoggingOut: boolean
}

const defaults = {
  items: []
};

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    isLoggedIn: false,
    accessToken: undefined,
    prevFailedUrl: '',
    rememberMe: false,
    isLoggingOut: false,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private authService: AuthService,
    private store: Store,
  ) {}

  accountStore = inject(AccountStore);

  @Selector()
  static isLoggedIn(state: AuthStateModel) {
    return state.isLoggedIn;
  }

  @Selector()
  static accessToken(state: AuthStateModel) {
    return state.accessToken;
  }

  @Selector()
  static isLoggingOut(state: AuthStateModel) {
    return state.isLoggingOut;
  }

  @Selector()
  static prevFailedUrl(state: AuthStateModel) {
    return state.prevFailedUrl;
  }

  @Selector()
  static rememberMe(state: AuthStateModel) {
    return state.rememberMe;
  }

  @Action(AuthAction.Initialize)
  async initializeAuth({
                         getState,
                         dispatch,
                         patchState,
                       }: StateContext<AuthStateModel>): Promise<void> {
    if (getState().isLoggedIn) {
      return;
    }

    const accessToken =
      getState().accessToken ||
      this.store.selectSnapshot(StorageState.accessToken);
    console.log('InitAuth - first token check', accessToken);

    if (isTokenValid(accessToken)) {
      dispatch(AuthAction.LoginSuccess);
      patchState({});
      return;
    }
    await lastValueFrom(dispatch(AuthAction.RefreshTokens).pipe(
      tap({
        next: () => {
          const renewedAccessToken = getState().accessToken;
          console.log('InitAuth - second token check', renewedAccessToken)

          // Logout when the refresh attempt still result in an invalid token
          if (!isTokenValid(renewedAccessToken)) {
            dispatch(AuthAction.Logout)
            return
          };

          patchState({})

          dispatch(AuthAction.LoginSuccess);
        }
      })
    ));
  }

  @Action(AuthAction.Login)
  login(
    { patchState, dispatch }: StateContext<AuthStateModel>,
    { email, password, rememberMe }: AuthAction.Login
  ): Observable<any> {
    patchState({ rememberMe });
    dispatch(new StorageAction.RememberMe(rememberMe));

    return this.authService.login({ email, password }).pipe(
      tap({
        next: (loginResponse) => {
          if (loginResponse) {
            dispatch(
              new AuthAction.UpdateTokens({
                accessToken: loginResponse.accessToken,
                refreshToken: loginResponse.refreshToken,
              })
            );
            dispatch(new AuthAction.LoginSuccess());
          }
        },
        error: (error) => {
          console.log('Login error');
          console.log(error);
          throw error;
        },
      })
    );
  }

  @Action(AuthAction.LoginSuccess)
  loginSuccess({ patchState, dispatch }: StateContext<AuthStateModel>) {
    patchState({
      isLoggedIn: true,
    });
    this.accountStore.initialize('1234');
  }

  /*@Action(AuthAction.RefreshTokens)
  async refreshToken({ patchState, dispatch, getState }: StateContext<AuthStateModel>, { refreshToken }: AuthAction.RefreshTokens): Promise<any> {
    const refreshTokenFromArg = refreshToken;
    const refreshTokenFromState = getState().refreshToken;
    const refreshTokenFromStorage = this.store.selectSnapshot(StorageState.refreshToken);
    const existingRefreshToken = refreshTokenFromArg || refreshTokenFromState || refreshTokenFromStorage

    if (!existingRefreshToken || !isTokenValid(existingRefreshToken)) {
      console.log('Refresh token is not found or the existing one is invalid');
      return;
    }

    console.log('Refresh token found and valid', existingRefreshToken);

    return lastValueFrom(this.authService.refreshTokens(existingRefreshToken).pipe(
      tap({
          next: (tokenPair) => {
            if (tokenPair) {
              dispatch(new AuthAction.UpdateTokens(tokenPair));
            }
          },
          error: (error) => {
            console.log('Refreshing token error');
            console.log(error);
            dispatch(AuthAction.Logout);
          }
        }
      )));
  }*/

  @Action(AuthAction.Logout)
  logout({ patchState, dispatch }: StateContext<AuthStateModel>) {
    patchState({
      isLoggedIn: false,
      accessToken: undefined,
      refreshToken: undefined,
      isLoggingOut: true,
    });
    dispatch(new StorageAction.ClearAccessRefreshToken());
    dispatch(new Navigate(['/login']));
  }

  @Action(AuthAction.UpdateTokens)
  updateToken(
    { patchState, dispatch, getState }: StateContext<AuthStateModel>,
    action: AuthAction.UpdateTokens
  ) {
    const {
      tokenPair: { accessToken, refreshToken },
    } = action;

    patchState({
      accessToken,
      refreshToken,
    });

    console.log('Updated tokens', accessToken, refreshToken);

    // accessToken need to be persisted so the app can keep authenticated state on page reload
    dispatch(new StorageAction.SetAccessToken(accessToken));

    if (getState().rememberMe) {
      dispatch(new StorageAction.SetRefreshToken(refreshToken));
    }
  }

  @Action(AuthAction.NavigationFailed)
  setFailedNavUrl(
    ctx: StateContext<AuthStateModel>,
    action: AuthAction.NavigationFailed
  ) {
    ctx.patchState({
      prevFailedUrl: action.url,
    });
  }
}
